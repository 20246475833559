* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-items: center;
}

.login, .signup, .forgot-password, .reset-password {
  background: #D9F6F3;
  padding: 50px 100px;
  border-top: 10px solid #ffce00;
}

label, input, textarea {
  display: block;
  width: 100%;
}

input, textarea {
  border: none;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 2px;
  background: #f8f4e5;
  padding-left: 5px;
  outline: none;
}

input:focus, textarea:focus {
  border: 1px solid #6bd4b1;
}

textarea{
  resize: none;
}
